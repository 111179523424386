<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal-sm modal-pick">
      <div class="modal-wrap">
        <div class="back-btn" @click="closeModal">
          <img src="@/assets/images/back.svg" alt="" />
        </div>
        <h2 class="ticket-title">Исполнитель</h2>
        <div class="close" @click="closeModal">&#10005;</div>
        <div class="search d-flex justify-space-around">
          <input type="text" placeholder="Поиск..." v-model="search" />
          <div
              @click="resetFilter"
              v-if="isFilter"
              class="reset-filter"
          >Сбросить фильтр</div>
        </div>
      </div>
      <div class="pick-items">
        <div class="item" v-for="n in 7" v-if="isLoading">
          <v-skeleton-loader
              type="list-item-avatar-two-line"
              height="56px"
              width="485px"
          ></v-skeleton-loader>
        </div>
        <div
          v-for="staff in executors"
          :key="staff.id"
          class="item"
          @click="select(staff.user)"
        >
          <div class="image">
            <img v-if="staff.avatar" :src="staff.avatar" alt="" />
            <img v-else src="@/assets/images/avatar.svg" alt="" />
          </div>
          <div class="text">
            <div class="name">{{ staff.name }}</div>
            <div class="job">{{ staff.position }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { staffApi } from '@/api/staff';
import pregQuote from '@/snippets';

export default {
  props:{
    ticketNumber:{
      type:Number,
      default:0,
    },
    isFilter: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    apiExecutors: [],
    search: '',
    isLoading: true,
  }),
  computed: {
    executors() {
      let executors = this.apiExecutors;
      if (this.search.length > 0) {
        const r = new RegExp(pregQuote(this.search), 'ig');
        executors = this.apiExecutors.filter((ex) => Object.values(ex).some((prop) => r.test(prop)));
      }
      return executors;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getExecutors();
    },
    closeModal() {
      this.$emit("close");
    },
    async getExecutors() {
      const res = await staffApi();
      this.apiExecutors = res.data.map((s) => ({
        id: s.id,
        position: s.position,
        name: `${s.user?.first_name} ${s.user?.last_name}`,
        avatar: s.user?.user_avatar?.avatar,
        user: s.user,
      }))
      this.isLoading=false;
    },
    select(staff) {
      this.$emit('select', staff.id);
      this.$emit('filter', staff);
      this.closeModal();
    },
    resetFilter(){
      this.$emit("reset")
      this.closeModal()
    }
  },
};
</script>

<style>
</style>